import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ScanType, Token } from './admin.interface';
import config from '../config';
import { setToFormDataUnserialized } from '../utils/helpers';

export const adminApi = createApi({
  reducerPath: 'adminApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${config.BASE_URL}/admin`,
  }),
  tagTypes: ['Tokens'],
  endpoints: (build) => ({
    getTokens: build.query<Token[], { access_key: string }>({
      query: (data) => `/tokens?access_key=${data.access_key}`,
      transformResponse: (response: { data: { tokens: Token[] } }) => response?.data?.tokens,
      providesTags: (result) => (result ? [...result.map(({ id }) => ({ type: 'Tokens' as const, id })), 'Tokens'] : ['Tokens']),
    }),
    createToken: build.mutation<
      Token,
      {
        access_key: string;
        customer: string;
        email_addresses?: string[];
        expiry_date?: string;
        remote_ip?: string;
        scan_dt_name?: string;
        scan_dt_version?: string;
        scan_type: ScanType;
      }
    >({
      query: (data) => ({
        url: '/tokens/create_token',
        method: 'POST',
        body: setToFormDataUnserialized(data),
      }),
      transformResponse: (response: { token: Token }) => response.token,
      invalidatesTags: () => ['Tokens'],
    }),
    removeTokens: build.mutation<{}, { token_ids: string; access_key: string }>({
      query: (data) => ({
        url: `/tokens/remove_token?access_key=${data.access_key}&token_ids=${data.token_ids}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Tokens', ids: arg?.token_ids }],
    }),
    validateAccessKey: build.mutation<{}, { access_key: string }>({
      query: (data) => ({
        url: '/validate_access_key',
        method: 'POST',
        body: setToFormDataUnserialized(data),
      }),
      transformResponse: (response: { token: Token }) => response.token,
    }),
    verifiedEmail: build.mutation<{}, { access_key: string; token_id: string; verified: boolean }>({
      query: (data) => ({
        url: '/verified_email',
        method: 'POST',
        body: setToFormDataUnserialized(data),
      }),
      transformResponse: (response: { email: string }) => response.email,
    }),
  }),
});

export const { useGetTokensQuery, useCreateTokenMutation, useRemoveTokensMutation, useValidateAccessKeyMutation, useVerifiedEmailMutation } = adminApi;
