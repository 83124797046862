import AddIcon from '@mui/icons-material/Add';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Tooltip,
} from '@mui/material';
import React, { ReactElement, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { ReactComponent as TrashIcon } from '../../../assets/images/icons/trash.svg';
import DetailsButton from '../dialogs/DetailsDialog';
import {
  RemoteEnvironment,
  RemoteEnvironmentProps,
  RemoteEnvironmentTableProps,
  SortState,
} from "../../../api/remote_servers.interface";
import {getCookie} from "../../../utils/cookies";
import {
  useAddRemoteEnvironmentMutation,
  useGetRemoteEnvironmentsQuery,
  useRemoveRemoteEnvironmentMutation
} from "../../../api/remote_servers";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";

const accessKey = getCookie('access_key');

function Row({ remoteEnvironment }: RemoteEnvironmentProps) {
  const { t } = useTranslation();
  const [removeRemoteEnvironment, { isLoading }] = useRemoveRemoteEnvironmentMutation();
  const onRemoveRemoteEnvironment = async (remote_ip: string) => {
    removeRemoteEnvironment({remote_ip: remote_ip, access_key: accessKey})
      .unwrap()
      .catch(() => {
        toast.error(t('remote-servers.failed-to-remove'));
      })
  };

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <Tooltip title={remoteEnvironment.remote_ip}>
            <Link target="_blank" rel="noreferrer" href={`https://${remoteEnvironment.remote_ip}`}>
              {remoteEnvironment.remote_ip.length > 30 ? `${remoteEnvironment.remote_ip.slice(0, 30)}...` : remoteEnvironment.remote_ip}
            </Link>
          </Tooltip>
        </TableCell>
        <TableCell>{remoteEnvironment.user}</TableCell>
        <TableCell>
          <div className="button-block" style={{ display: 'flex', alignItems: 'center' }}>
            <Button type="button" disabled={isLoading} onClick={() => onRemoveRemoteEnvironment(remoteEnvironment.remote_ip)}>
              <TrashIcon />
            </Button>
            <DetailsButton remoteEnvironment={remoteEnvironment} />
          </div>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function RemoteEnvironmentsTable({ remoteEnvironments }: RemoteEnvironmentTableProps) {
  const { t } = useTranslation();
  const [sort, setSort] = useState<SortState>({ field: 'remote_ip', order: 'asc' });
  const sortedRows = sortItems(remoteEnvironments, sort);

  function sortItems(items: RemoteEnvironment[], sort: SortState): RemoteEnvironment[] {
    return [...items].sort((a, b) => {
      if (!sort.field) return 0;

      const aValue = a[sort.field];
      const bValue = b[sort.field];

      if (typeof aValue === 'undefined' || typeof bValue === 'undefined') return 0;
      if (aValue < bValue) return sort.order === 'asc' ? -1 : 1;
      if (aValue > bValue) return sort.order === 'asc' ? 1 : -1;

      return 0;
    });
  }

  const handleSort = (field: keyof RemoteEnvironment) => {
    const isAsc = sort.field === field && sort.order === 'asc';
    setSort({ field, order: isAsc ? 'desc' : 'asc' });
  };

  return (
    <TableContainer component={Paper} sx={{ width: 800 }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: 250 }}>
              <TableSortLabel active={sort.field === 'remote_ip'} direction={sort.order} onClick={() => handleSort('remote_ip')}>
                {t('remote-servers.remote-ip')}
              </TableSortLabel>
            </TableCell>
            <TableCell sx={{ width: 250 }}>
              <TableSortLabel active={sort.field === 'user'} direction={sort.order} onClick={() => handleSort('user')}>
                {t('remote-servers.user')}
              </TableSortLabel>
            </TableCell>
            <TableCell sx={{ width: 100 }} />
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedRows.length > 0 ? (
            sortedRows.map((row) => <Row key={row.remote_ip} remoteEnvironment={row} />)
          ) : (
            <TableRow style={{ height: '120px' }}>
              <TableCell colSpan={4} align="center">
                {t('remote-servers.no-environments')}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function RemoteServers(): ReactElement {
  const {
    register,
    handleSubmit,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } = useForm<any>({
    criteriaMode: 'all',
  });
  const { t } = useTranslation();
  const history = useHistory();

  const [stateRemoteEnvironmentAddingDialog, setStateRemoteEnvironmentAddingDialog] = React.useState(false);
  const onOpenRemoteEnvironmentAddingDialog = () => setStateRemoteEnvironmentAddingDialog(true);
  const onCloseRemoteEnvironmentAddingDialog = () => setStateRemoteEnvironmentAddingDialog(false);

  const { data } = useGetRemoteEnvironmentsQuery({ access_key: accessKey });
  const [addRemoteEnvironment] = useAddRemoteEnvironmentMutation();

  interface RemoteEnvironmentData {
    remote_ip: string;
    user: string;
    password: string;
  }

  const onAddRemoteEnvironment = async (data: RemoteEnvironmentData) => {
    addRemoteEnvironment({access_key: accessKey, remote_ip: data.remote_ip, user: data.user, password: data.password })
      .unwrap()
      .catch((error) => {
        onCloseRemoteEnvironmentAddingDialog();
        if (error?.data?.error?.message) {
          toast.error(error?.data?.error?.message)
        } else {
          toast.error(`Error occurred: ${JSON.stringify(error?.data)}`)
        }
      })
    onCloseRemoteEnvironmentAddingDialog();
  };

  const handleBack = () => history.push("/admin/access");

  return (
    <form>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <h1>{t('remote-servers.title')}</h1>
          <p>{t('remote-servers.description')}</p>
        </Grid>
        <Grid item>
          <Button variant="contained" size="large" onClick={handleBack}>
            {t('remote-servers.back-button')}
          </Button>
        </Grid>
      </Grid>

      <Grid container justifyContent="flex-start" sx={{ marginTop: 2 }}>
        <Button sx={{ height: 40, width: 140 }} onClick={onOpenRemoteEnvironmentAddingDialog} variant="text" startIcon={<AddIcon />}>
          {t('remote-servers.add-server')}
        </Button>
      </Grid>
      <Grid container sx={{ marginTop: 3 }}>
        <RemoteEnvironmentsTable remoteEnvironments={data || []} />
      </Grid>
      <Dialog open={stateRemoteEnvironmentAddingDialog} onClose={onCloseRemoteEnvironmentAddingDialog}>
        <DialogTitle sx={{ textAlign: "center" }}>{t('remote-servers.new-remote-server')}</DialogTitle>
        <DialogContent sx={{ width: 350 }}>
          <form onSubmit={handleSubmit(onAddRemoteEnvironment)}>
            <Grid container justifyContent="center" spacing={2}>
              <Grid item xs={12}>
                <InputLabel htmlFor="remote-ip" shrink={false}>
                  {t('remote-servers.remote-ip')}*
                </InputLabel>
                <TextField
                  size="small"
                  fullWidth
                  required
                  {...register('remote_ip', { required: 'Required field' as string })}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel htmlFor="user" shrink={false}>
                  {t('remote-servers.user')}*
                </InputLabel>
                <TextField
                  size="small"
                  fullWidth
                  required
                  defaultValue="sysadmin"
                  {...register('user', { required: 'Required field' as string })}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel htmlFor="password" shrink={false}>
                  {t('remote-servers.password')}*
                </InputLabel>
                <TextField
                  size="small"
                  fullWidth
                  required
                  {...register('password', { required: 'Required field' as string })}
                />
              </Grid>
              <Grid item xs={6}>
                <Button fullWidth type="submit" variant="contained">
                  {t('remote-servers.add-server')}
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </form>
  );
}

export default RemoteServers;
