import { createApi } from "@reduxjs/toolkit/query/react";
import { BaseQueryFn } from "@reduxjs/toolkit/query";
import { nanoid } from "nanoid";
import axios, { AxiosRequestConfig, AxiosError } from "axios";
import axiosCancel from "axios-cancel";
import { Token } from "./upload.interface";
import config from "../config";
import { setToFormDataUnserialized } from "../utils/helpers";

axiosCancel(axios as any);

const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: "" }
  ): BaseQueryFn<
    {
      url: string;
      method: AxiosRequestConfig["method"];
      data?: AxiosRequestConfig["data"];
      onUploadProgress?: AxiosRequestConfig["onUploadProgress"];
      requestId?: string;
    },
    unknown,
    unknown
  > =>
  async ({ url, method, data, onUploadProgress, requestId }) => {
    try {
      // @ts-ignore
      const result = await axios({ url: baseUrl + url, method, data, onUploadProgress, requestId: requestId || nanoid() });
      return { data: result.data };
    } catch (axiosError) {
      let err = axiosError as AxiosError;
      if (axios.isCancel(err)) {
        return {
          error: { status: "cancel", data: "Request canceled" }
        };
      } else {
        return {
          error: { status: err.response?.status, data: err.response?.data }
        };
      }
    }
  };

export const uploadApi = createApi({
  reducerPath: "uploadApi",
  baseQuery: axiosBaseQuery({
    baseUrl: `${config.BASE_URL}/upload`
  }),
  endpoints: (build) => ({
    validateToken: build.mutation<{}, { token_id: string }>({
      query: (data) => ({
        url: "/validate_token",
        method: "POST",
        data: setToFormDataUnserialized(data)
      }),
      transformResponse: (response: { token: Token }) => response.token
    }),
    uploadFiles: build.mutation<
      {},
      {
        is_microcontroller?: boolean;
        architecture?: string;
        component_type?: string;
        token_id: string;
        dt_name?: string;
        dt_version_name?: string;
        resumable_identifiers?: string[];
        resumable_session_id?: string;
      }
    >({
      query: (data) => {
        const bodyFormData = new FormData();

        return {
          url: "/upload_files",
          method: "POST",
          data: setToFormDataUnserialized(data, bodyFormData),
        };
      },
      transformResponse: (response: { data: { token: { email_addresses: string[] } } }) => ({
        email_addresses: response?.data?.token?.email_addresses ? response?.data?.token?.email_addresses.filter((email) => email !== "") : []
      })
    })
  })
});

export const { useValidateTokenMutation, useUploadFilesMutation } = uploadApi;
