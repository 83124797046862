import { LoadingButton } from '@mui/lab';
import { Box, Button, Grid, IconButton, InputLabel, Paper, Tooltip, Typography } from '@mui/material';
import React, { useCallback, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { useUploadFilesMutation } from '../../../api/upload';
import Input from '../../../components/input/Input';
import UploadProgress from '../../../components/uploadProgress/UploadProgress';
import { ReactComponent as EvaluationIcon } from '../../../assets/images/icons/evaluation.svg';
import useVerifyTokenExists from '../hooks/useVerifyTokenExists';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import { resumableUploadsConfigSelector, setTokenSuccess, setTokenUpload } from '../tokenSlice';
import useResumable from '../hooks/useResumable';
import { getAppSessionId } from '../../../app/appSlice';
import { isEmpty } from 'lodash';
import { ReactComponent as TrashIcon } from '../../../assets/images/icons/trash.svg';
import { ReactComponent as FileIcon } from '../../../assets/images/icons/file.svg';
import { formatBytes } from '../../../utils/helpers';

function UploadFiles() {
  const { t } = useTranslation();
  const history = useHistory();

  const filesRef = useRef<HTMLInputElement | null>(null);
  const dispatch = useDispatch();

  const { information: informationState } = useSelector((state: RootState) => state.token);
  useVerifyTokenExists();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const { name, version, component, fileResumable } = watch();
  const [sendFilesData, { isLoading }] = useUploadFilesMutation();

  const resumableSessionId = useSelector(getAppSessionId);
  const resumableUploadsConfig = useSelector(resumableUploadsConfigSelector);
  const { uploadStats, files, uploadFiles, cancelUpload, removeFile } = useResumable({
    slug: 'VERIFICATION',
    browseRef: filesRef,
    skip: false,
  });

  const onSubmit = async (data: { name: string; version: string; component: string }) => {
    try {
      const resumableIdentifiers = await uploadFiles();
      const dataToSend = {
        ...(informationState.controller !== '' ? { is_microcontroller: informationState.controller === 'true' } : {}),
        ...(informationState.architecture && informationState.architecture !== 'Other' ? { architecture: informationState.architecture } : {}),
        ...(informationState.architecture && informationState.architecture === 'Other' ? { architecture: informationState.other } : {}),
        token_id: informationState.token_id as string,
        dt_name: data.name,
        dt_version_name: data.version,
        ...(!data.component ? { component_type: data.component } : {}),
        ...(resumableIdentifiers ? { resumable_identifiers: resumableIdentifiers } : {}),
        ...(resumableSessionId ? { resumable_session_id: resumableSessionId } : {}),
      };

      sendFilesData(dataToSend)
        .unwrap()
        .then(async (result) => {
          toast.success(t('upload-files.success-1'));
          dispatch(setTokenSuccess(result));
          history.replace(`/token/success`);
        })
        .catch((error) => {
          toast.error(t('upload-files.failure'));
        });
    } catch (e) {
      toast.error(t('upload-files.failure'));
    }
  };

  useEffect(() => {
    if (files) {
      setValue(
        'fileResumable',
        Object.keys(files).map((key) => ({
          id: files[key].id,
          name: files[key].file.file.name,
          path: files[key].file.relativePath,
          size: files[key].file.size,
          error: files[key].isError,
        })),
      );
    }
  }, [files, setValue]);

  const handleRemoveFile = useCallback(
    (event: React.SyntheticEvent): void => {
      const { dataset } = event.currentTarget as HTMLButtonElement;
      if (dataset.id) {
        removeFile(dataset.id);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [removeFile, setValue, resumableUploadsConfig?.enabled],
  );

  const handleBack = () => {
    dispatch(setTokenUpload({ name, version, component }));
    history.push(`/token/information`);
  };

  const width = 400;
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="upload-files-container">
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <p>{t('upload-files.description')}</p>
        </Grid>
      </Grid>
      <Box sx={{ mt: '24px', maxWidth: width, minWidth: width }}>
        <Input
          label={t('upload-files.name')}
          id="name"
          placeholder={t('upload-files.add-name')}
          {...register('name', { required: true })}
          errors={errors}
          disabled={uploadStats.uploading || isLoading}
        />
        <Box sx={{ mt: '24px' }}>
          <Input
            label={t('upload-files.version')}
            id="version"
            placeholder={t('upload-files.add-version')}
            {...register('version', { required: true })}
            errors={errors}
            disabled={uploadStats.uploading || isLoading}
          />
        </Box>
        <Box sx={{ mt: '24px' }}>
          <Input
            label={t('upload-files.component')}
            id="component"
            placeholder={t('upload-files.add-component')}
            {...register('component')}
            errors={errors}
            disabled={uploadStats.uploading || isLoading}
          />
        </Box>
        <Box sx={{ mt: '24px', textAlign: 'left' }}>
          <InputLabel shrink sx={{ mb: '8px' }} required>
            {t('upload-files.upload-file')}
          </InputLabel>
          <input accept="*" id="upload-files" type="file" name="file" hidden ref={filesRef} />
          <label htmlFor="upload-files">
            <Button
              variant="contained"
              color="primary"
              component="span"
              fullWidth={true}
              className="xs-pl-0 button-info"
              sx={{ marginBottom: 3 }}
              disabled={uploadStats.uploading || isLoading}
            >
              {isEmpty(fileResumable) ? t('file-dropzone.upload-files') : t('file-dropzone.update-files')}
            </Button>
          </label>
          {fileResumable?.map((value: { id: string; name: string; path: string; size: number }) => (
            <Paper>
              <Grid container alignItems="center" key={value.id} className="file-name" sx={{ marginTop: 1, marginBottom: 1 }}>
                {!uploadStats.uploading && !isLoading && (
                  <Grid justifyContent={'center'} item xs={1} sx={{ margin: 1 }}>
                    <IconButton className="remove-file" data-id={value.id} onClick={handleRemoveFile}>
                      <div className="trash-icon-background"></div>
                      <TrashIcon />
                    </IconButton>
                  </Grid>
                )}
                <Grid justifyContent={'center'} item xs={1} sx={{ margin: 1 }}>
                  <FileIcon className="file-icon" />
                </Grid>
                <Grid justifyContent={'center'} item xs={5} sx={{ margin: 1 }}>
                  <Tooltip title={value.path}>
                    <Typography noWrap>{value.path}</Typography>
                  </Tooltip>
                </Grid>
                <Grid justifyContent={'center'} item xs={3} sx={{ margin: 1 }}>
                  {formatBytes(value.size)?.sizeLabel}
                </Grid>
              </Grid>
            </Paper>
          ))}
        </Box>
        {uploadStats.uploading && (
          <Box sx={{ mt: '40px' }}>
            <UploadProgress uploadStats={uploadStats} cancelUpload={cancelUpload} />
          </Box>
        )}
        {isLoading && (
          <Paper sx={{ height: 65, marginTop: '40px' }}>
            <div style={{ display: 'inline-block', fontSize: 20, padding: 20 }}>{t('upload-files.processing-your-files')}</div>
          </Paper>
        )}
        <Grid sx={{ mt: '24px' }} container spacing={3} justifyContent="space-between" wrap="nowrap">
          <Grid item className="full-width">
            <Button disabled={uploadStats.uploading || isLoading} variant="contained" size="large" onClick={handleBack} className="full-width">
              {t('upload-files.back')}
            </Button>
          </Grid>
          <Grid item className="full-width">
            <LoadingButton
              variant="contained"
              type="submit"
              disabled={isEmpty(fileResumable)}
              loading={uploadStats.uploading || isLoading}
              size="large"
              className="full-width"
            >
              {t('upload-files.upload')}
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ mt: '40px' }}>
        <EvaluationIcon />
        <Box sx={{ mt: '40px' }}>{t('upload-files.evaluated')}</Box>
        <Grid container spacing={4}>
          <Grid item>
            <ul>
              <li>{t('upload-files.text-li1')}</li>
              <li>{t('upload-files.text-li2')}</li>
              <li>{t('upload-files.text-li3')}</li>
            </ul>
          </Grid>
          <Grid item>
            <ul>
              <li>{t('upload-files.text2-li1')}</li>
              <li>{t('upload-files.text2-li2')}</li>
              <li>{t('upload-files.text2-li3')}</li>
            </ul>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
}

export default UploadFiles;
