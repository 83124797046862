import { Box, Button, Grid } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Table from '../../../components/table/Table';
import TableMenu from '../../../components/table/TableMenu';
import InfoIcon from '@mui/icons-material/Info';
import { getCookie } from '../../../utils/cookies';
import { useGetTokensQuery } from '../../../api/admin';
import { getDate } from '../../../utils/helpers';
import useToggleModals from '../../../hooks/useToggleModals';
import { Token } from '../../../api/admin.interface';
import MoreInformationDialog from '../dialogs/MoreInformationDialog';
import { Email, MailOutline } from '@mui/icons-material';
import EmailDialog from '../dialogs/EmailDialog';

function AdminAccess() {
  const { t } = useTranslation();
  const history = useHistory();
  const accessKey = getCookie('access_key');
  const { data, isLoading } = useGetTokensQuery({ access_key: accessKey });
  const [selectedToken, setSelectedToken] = useState<Token>();

  const { modals, toggleModals } = useToggleModals({
    info: false,
    verified: false,
    notVerified: false,
  });

  const handleCreate = () => history.push('/admin/create');
  const handleRemoteInstances = () => history.push('/admin/remote-servers');
  const handleAction = useCallback(
    ({ id, option }: { id: string; option: string }) => {
      const selectedToken = data?.find((item) => item.id === id);
      if (selectedToken) {
        setSelectedToken(selectedToken);
        toggleModals(option);
      }
    },
    [data, toggleModals],
  );

  const actions = useCallback(
    (used) => [
      ...(used
        ? [
            {
              option: 'verified',
              icon: <Email />,
              label: t('admin.binary-verified'),
            },
            {
              option: 'notVerified',
              icon: <MailOutline />,
              label: t('admin.binary-not-verified'),
            },
          ]
        : []),
      {
        option: 'info',
        icon: <InfoIcon />,
        label: t('admin.more-info'),
      },
    ],
    [t],
  );

  const scanStatusMap = {
    created: 'Created',
    uploaded_to_s3: 'Uploaded to S3',
    scan_started: 'Scan started',
    scan_finished: 'Scan finished',
    scan_failed: 'Scan failed',
  };

  const columns = useMemo(
    () => [
      {
        id: 'id',
        label: t('admin.token'),
      },
      {
        id: 'used',
        label: t('admin.used'),
        Cell: (row: Token) => (row.used ? t('information.yes') : t('information.no')),
      },
      {
        id: 'customer',
        label: t('admin.customer'),
      },
      {
        id: 'created_on',
        label: t('admin.created-on'),
        Cell: (row: Token) => getDate(row.created_on),
      },
      {
        id: 'expiry_date',
        label: t('admin.date'),
        Cell: (row: Token) => (row.expiry_date === 'null' ? t('admin.never') : getDate(row.expiry_date)),
      },
      {
        id: 'email_addresses',
        label: t('admin.emails'),
        Cell: (row: Token) => row.email_addresses?.join(', '),
      },
      {
        id: 'status',
        label: t('Status'),
        Cell: (row: Token) => scanStatusMap[row.status],
      },
      {
        id: 'actions',
        label: '',
        Cell: (row: Token) => <TableMenu actions={actions(row.used)} itemId={row.id} handleAction={handleAction} />,
        width: '50px',
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [actions, handleAction, t],
  );

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <h1>{t('admin.title-access')}</h1>
          <p>{t('admin.description-access')}</p>
        </Grid>
        <Grid item>
          <Button variant="contained" size="large" onClick={handleRemoteInstances} sx={{ marginRight: 2 }}>
            {t('admin.remote-instances')}
          </Button>
          <Button variant="contained" size="large" onClick={handleCreate}>
            {t('admin.create')}
          </Button>
        </Grid>
      </Grid>

      <Table columns={columns} rows={data || []} selectedCheckbox={true} loading={isLoading} pagination={data && data?.length > 0 && !isLoading} />
      {(!data || (data?.length === 0 && !isLoading)) && (
        <Box className="full-width" sx={{ textAlign: 'center' }} typography="h6">
          {t('admin.no-data')}
        </Box>
      )}
      <EmailDialog
        open={modals.verified || modals.notVerified}
        setOpen={() => toggleModals(modals.verified ? 'verified' : 'notVerified')}
        token={selectedToken}
        verified={modals.verified}
      />
      <MoreInformationDialog open={modals.info} setOpen={() => toggleModals('info')} token={selectedToken} />
    </>
  );
}

export default AdminAccess;
